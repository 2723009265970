import Button from "./buttons";
import { Title } from "../section/sectionSubcomponents";
import { useState } from "react";
import CustomModal from "../modals/modals";
import RedArrows from "../../assets/red_arrows.png";
import GreenArrows from "../../assets/green_arrows.png";
import { PictureInput, TextInput } from "../helpers/forms/inputs";
import { Formik } from "formik";
import { sendAuthPostRequest } from "../helpers/api/Requests";

export default function ShopObject({
  _id,
  socket,
  priceChange,
  buyPrice,
  capacity,
  objectName,
  sellPrice,
  image,
}) {
  const [show, setShow] = useState(false);
  const [woodDescriptionShow, setWoodDescriptionShow] = useState(false);
  const [description, setDescription] = useState("");
  const [buyOrSell, setBuyOrSell] = useState("");

  const handleConfirmBuy = () => {
    setBuyOrSell("buy");
    if (objectName === "Lemn") {
      handleWoodDescriptionShow();
      return;
    }
    setShow(true);
  };

  const handleBuy = () => {
    setShow(false);
    socket.emit("buyItem", { itemId: _id });
  };

  const handleWoodDescriptionShow = () => {
    setWoodDescriptionShow(true);
  };

  const handleWoodDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const closeWoodDescription = () => {
    setWoodDescriptionShow(false);
  };

  const handleConfirmSale = () => {
    setBuyOrSell("sell");
    setShow(true);
  };

  const handleSell = () => {
    setShow(false);
    socket.emit("sellItem", { itemId: _id });
  };

  const closeModal = () => {
    setBuyOrSell("");
    setShow(false);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <div className="w-11/12 md:w-4/5 justify-center gap-4 bg-generalBG text-white flex flex-col items-center text-lg lg:text-2xl rounded-lg border-2 border-gray-600 lg:border-none shadow-md">
        <div className="w-full flex flex-col items-center justify-center gap-8">
          <Title>{objectName}</Title>
          <img
            src={image}
            alt={objectName}
            className="h-20 w-20 lg:h-32 lg:w-32"
          />
          {sellPrice !== undefined && (
            <div className="flex flex-col items-center gap-2">
              <p className="mb-2">{sellPrice}</p>
              {priceChange !== 0 && <div className="flex ml-2.5">
                <div className="flex-1 h-8">
                  <p className="mb-1">{`${priceChange > 0 ? '+' : ''}${priceChange}%`}</p>
                </div>
                {priceChange > 0 ? (
                  <div className="flex-1 h-8 w-10">
                    <img src={GreenArrows} alt="Green Arrows" className="mt-[-12px]" />
                  </div>
                ) : (
                  <div className="flex-1 h-8 w-10">
                    <img src={RedArrows} alt="Red Arrows" className="mt-1.5 ml-[-4px]" />
                  </div>
                )}
              </div>}
              <Button className="mb-6" name="gray" onClick={handleConfirmSale}>Sell</Button>
            </div>
          )}
          {buyPrice !== undefined && (
            <div className="flex flex-col items-center gap-2">
              <p className="mb-2">{buyPrice}</p>
              {priceChange !== 0 && <div className="flex ml-2.5">
                <div className="flex-1 h-8">
                  <p className="mb-1">{`${priceChange > 0 ? '+' : ''}${priceChange}%`}</p>
                </div>
                {priceChange > 0 ? (
                  <div className="flex-1 h-8 w-10">
                    <img src={GreenArrows} alt="Green Arrows" className="mt-[-12px]" />
                  </div>
                ) : (
                  <div className="flex-1 h-8 w-10">
                    <img src={RedArrows} alt="Red Arrows" className="mt-1.5 ml-[-4px]" />
                  </div>
                )}
              </div>}
              <Button className="mb-4" name="gray" onClick={handleConfirmBuy}>Buy</Button>
              <p className="italic text-lg lg:text-2xl font-medium mb-6">
                Stock: {capacity}
              </p>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        show={show}
        onHide={closeModal}
        onCompleteAction={
          buyOrSell === "buy" && buyOrSell !== "" ? handleBuy : handleSell
        }
        type={"normal"}
        text={`Do you want to ${buyOrSell} ${objectName}?`}
      />
      {woodDescriptionShow ? (
        <div className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-40">
          <div className="p-6 rounded-lg bg-generalBG shadow-md h-2/5 flex flex-col justify-center">
            <h1 className="text-2xl text my-2 text-center md:text-5xl text-white pb-8" children="Cumpara Lemn" />
            <Formik
              initialValues={{
                description: "",
                file: null,
              }}
              onSubmit={async (values) => {
                const file = values.file;
                const base64Image = await convertFileToBase64(file);
                socket.emit("buyWood", { description: values.description, file: base64Image });
                setWoodDescriptionShow(false);
              }}
            >
              {({ values, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex w-10/12 mx-8">
                    <div className="w-full items-center justify-center gap-y-2">
                      <div className="w-full flex flex-col gap-y-4">
                        <TextInput
                          name="description"
                          label="Descriere"
                          type="text"
                          placeholder="Descriere"
                          onChange={handleWoodDescriptionChange}
                        />
                        <PictureInput
                          label="Adauga fisier"
                          name="file"
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className="flex flex-row justify-center items-center mt-6">
                        <Button
                          className="!text-2xl mx-5"
                          onClick={closeWoodDescription}
                          children="Exit"
                          name="gray"
                        />
                        <Button
                          className="!text-lg md:!text-2xl mx-2 md:mx-5"
                          type="submit"
                          children="Buy"
                          name="gray"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      ) : null}
    </>
  );
}
