import React from "react";
import Button from "../helpers/buttons";

const Modal = ({ show, onHide, onCompleteAction, type, text }) => {
  const getModalStyle = () => {
    switch (type) {
      case "success":
        return "bg-green-800";
      case "error":
        return "bg-red-800";
      default:
        return "bg-zinc-700";
    }
  };

  console.log(text);

  return show ? (
    <div className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-40">
      <div className={`p-6 md:mx-0 mx-2 rounded-lg ${getModalStyle()} shadow-md h-72 flex flex-col justify-center`}>
        <div className="py-1 rounded-2xl text-white mx-auto w-5/6">
          <h1 className="text-2xl md:my-2 text-center lg:text-5xl md:text-4xl">{text}</h1>
        </div>
        {type === "normal" && (
          <div className="flex justify-center mt-8 md:mt-12">
            <Button className="!text-2xl mx-5" onClick={onCompleteAction} children="Yes" name="gray" />
            <Button className="!text-2xl mx-5" onClick={onHide} children="Exit" name="gray" />
          </div>
        )}
        {type !== "normal" && (
          <div className="flex justify-center mt-8 md:mt-12">
            <Button className="!text-xl md:!text-2xl mx-5" onClick={onHide} children="Exit" name="black" />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Modal;
