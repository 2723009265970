import React from 'react';

export default function SponsorModal({ img, description, link, onClose }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-generalBG p-6 max-w-md mx-3 rounded-3xl overflow-y-auto relative z-50 border border-black">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex items-center justify-center mb-4">
          <img src={img} alt="Sponsor" className="w-full h-auto" />
        </div>
        <div className="text-lg text-white max-h-80 rounded-3xl overflow-y-auto border border-black p-4">
          <p className='mb-2'>{description}</p>
          {link && (
            <a href={link} className="text-white underline">
              Find more here!
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
