import React, { useState, useEffect } from "react";
import { sendAuthGetRequest } from "../../components/helpers/api/Requests";
import { useMediaQuery } from "react-responsive";
import Wood from "../../components/admin/wood";
import io from "socket.io-client";
import Auth from "../../modules/Auth";

const AdminMesteri = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const [showDetails, setShowDetails] = useState({});
  const [items, setItems] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [error, setError] = useState("");
  const [socket, setSocket] = useState(io);

  useEffect(() => {
    let socketRef = socket;
    socketRef = io(`${process.env.REACT_APP_API_URL}/admins`, {
      transports: ["websocket"],
      auth: {
        token: Auth.getToken(),
      },
    });
    setSocket(socketRef);
    sendAuthGetRequest(
      `${process.env.REACT_APP_API_URL}/api/admin/get-wood-items`
    )
      .then((res) => {
        setItems(res.data.map((el) => ({ ...el })));
      })
      .catch((err) => {
        setError(err);
      });
    return () => {
      socketRef.removeAllListeners();
      socketRef.disconnect();
      socketRef.offAny();
      socketRef.offAnyOutgoing();
    };
  }, []);

  useEffect(() => {
    items.forEach(async (item) => {
      sendAuthGetRequest(
        `${process.env.REACT_APP_API_URL}/api/admin/get-team/${item.teamId}`,
        null
      )
        .then((res) => {
          setTeamData((prevData) => [
            ...prevData,
            { teamId: item.teamId, teamName: res.data.name },
          ]);
        })
        .catch((err) => {
          setError(err);
        });
    });
  }, [items]);

  const handleClick = (item) => {
    socket.emit("joinBuilderRoom");
    const updatedItems = items.map((i) => {
      if (i._id === item._id) {
        i.isDone = !i.isDone;
        return i;
      }
      return i;
    });
    setItems(updatedItems);
    socket.emit("finishBuild", { woodId: item._id });
  };

  const handleDropdownToggle = (item) => {
    setShowDetails((prevShowDetails) => ({
      ...prevShowDetails,
      [item._id]: !prevShowDetails[item._id],
    }));
  };

  return (
    <div className="flex justify-center">
      <div className="mx-4 sm:mx-0 w-5/6 bg-generalBG md:p-12 p-4 rounded-5xl shadow-2xl flex flex-col md:my-8 my-4">
        <div className="overflow-x-auto w-full h-[50vh] text-white">
          {items.map((item) => (
            <Wood
              key={item._id}
              item={item}
              selectedItem={showDetails[item._id] ? item : null}
              showDetails={showDetails[item._id]}
              handleClick={handleClick}
              handleDropdownToggle={handleDropdownToggle}
              teamName={
                (teamData.find((team) => team.teamId === item.teamId) || {})
                  .teamName
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminMesteri;
