import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import Modal from '../modals/addModal';
import { sendAuthGetRequest } from '../helpers/api/Requests';

const Table = ({ id, headers, rows, socket }) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    const [accordionOpen, setAccordionOpen] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [data, setData] = useState({});
    const [error, setError] = useState("");

    const toggleAccordion = (index) => {
        setAccordionOpen(accordionOpen === index ? null : index);
        setSelectedRow(index);
    };

    const handleInfo = async (teamId, type) => {
        const res = await sendAuthGetRequest(`${process.env.REACT_APP_API_URL}/api/admin/get-team/${teamId}`);
        if (res.error) {
            setError(res.error);
        } else {
            setData(res.data)
        }
        setModalType(type);
        setShowModal(!showModal);

    }

    const addOnStock = (id) => {
        socket.emit("modifyStock", { id: id, amount: 1 })
    }

    const removeFromStock = (id) => {
        socket.emit("modifyStock", { id: id, amount: -1 });
    }

    const deliver = (id, isDelivered) => {
        if(!isDelivered)
            socket.emit("deliverTransaction", { idTransaction: id });
    }

    if (!isDesktop) {
        // Render Accordion
        return (
            <div className="overflow-x-auto w-full lg:w-4/6 bg-generalBG p-4 sm:p-8 rounded-5xl text-white">
                {rows.map((row, index) => (
                    <div key={index} className="mb-2 sm:mb-4">
                        <div className="flex justify-between items-center border-b border-white">
                            <h2 className="text-lg sm:text-2xl font-bold">{id === 1 ? "Transaction " + index : row[1]}</h2>
                            <button onClick={() => toggleAccordion(index)} className="text-lg sm:text-2xl font-bold material-icons">
                                expand_more
                            </button>
                        </div>
                        <div className={`mt-4 overflow-hidden transition-max-height ease-in-out duration-500 ${accordionOpen === index ? 'max-h-96' : 'max-h-0'}`}>
                            {/* Accordion Content */}
                            <ul>
                                {row.map((cell, cellIndex) => (
                                    <li key={cellIndex} className='text-md flex justify-start' >
                                        {id === 1 && cellIndex === row.length - 1 ? (
                                            <button className={`${cell ? 'bg-green-400 ' : 'bg-yellow-400 '} text-white font-bold px-4 py-2 rounded-xl`} onClick={() => deliver(row[0], row[5])}>
                                                {cell ? "Delivered" : "Deliver"}
                                            </button>
                                        ) : (
                                            <div className='whitespace-pre-wrap break-all'>
                                                {cellIndex !== 0 && (
                                                    <div>
                                                        <span className='font-bold'>{headers[cellIndex - 1]}: </span>
                                                        <span>{cell}</span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                ))}
                                {/* butoane add si remove produse */}
                                {id === 2 && (
                                    <li className='text-md flex flex-wrap justify-evenly mt-2'>
                                        <button onClick={() => addOnStock(row[0])} className="bg-green-400 text-white font-bold px-4 py-2 rounded-xl">Add</button>
                                        <button onClick={() => removeFromStock(row[0])} className="bg-red-400 text-white font-bold px-4 py-2 rounded-xl">Remove</button>
                                    </li>
                                )}
                                {/* butoane echipe */}
                                {id === 3 && (
                                    <li className='text-md flex flex-col sm:flex-row flex-wrap justify-evenly mt-2 w-full'>
                                        <button onClick={() => handleInfo(row[0], "addPoints")} className="bg-grayBTN text-white font-bold px-4 py-2 rounded-xl mt-2 sm:mt-0">Adauga puncte</button>
                                        <button onClick={() => handleInfo(row[0], "removePoints")} className="bg-grayBTN text-white font-bold px-4 py-2 rounded-xl mt-2 sm:mt-0">Scade puncte</button>
                                        <button onClick={() => handleInfo(row[0], "moreInfo")} className="bg-grayBTN text-white font-bold px-4 py-2 rounded-xl mt-2 sm:mt-0">More info</button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
                <Modal show={showModal} onHide={() => setShowModal(false)} type={modalType} data={data} socket={socket} />
            </div>
        );
    }

    return (
        //Render Table
        <div className="overflow-x-auto w-full h-[50vh] text-white">
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index} className="px-4 py-2 text-3xl text-left border-b border-white font-bold">{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                // aici arata statusul de la tranzactii
                                id === 1 && cellIndex === row.length - 1 ? (
                                    <td key={cellIndex} className='border-b border-white px-4 py-2 text-md whitespace-pre-wrap'>
                                        <button className={`font-bold px-4 py-2 rounded-xl text-white ${cell ? 'bg-green-400 ' : 'bg-yellow-400 '}`} onClick={() => deliver(row[0], row[5])}>
                                            {cell ? "Delivered" : "Deliver"}
                                        </button>
                                    </td>
                                ) :
                                    (
                                        cellIndex !== 0 && (
                                            <td key={cellIndex} className='border-b border-white px-4 py-2 text-md whitespace-pre-wrap break-all'>{cell}</td>
                                        ) 
                                    )
                            ))}
                            {/* aici trebuie adaugate butoanele add si remove de la produse */}
                            {id === 2 && (
                                <td className='border-b border-white px-4 py-2 text-md whitespace-pre-wrap'>
                                    <button onClick={() => addOnStock(row[0])} className="bg-green-400 text-white font-bold px-4 py-2 rounded-xl">Add</button>
                                </td>
                            )}
                            {id === 2 && (
                                <td className='border-b border-white px-4 py-2 text-md whitespace-pre-wrap'>
                                    <button onClick={() => removeFromStock(row[0])} className="bg-red-400 text-white font-bold px-4 py-2 rounded-xl">Remove</button>
                                </td>
                            )}
                            {id === 3 && (
                                <td className='border-b border-white px-4 py-2 text-md whitespace-pre-wrap'>
                                    <button onClick={() => handleInfo(row[0], "addPoints")} className="bg-grayBTN text-white font-bold px-4 py-2 rounded-xl">Adauga puncte</button>
                                </td>
                            )}
                            {id === 3 && (
                                <td className='border-b border-white px-4 py-2 text-md whitespace-pre-wrap'>
                                    <button onClick={() => handleInfo(row[0], "removePoints")} className="bg-grayBTN text-white font-bold px-4 py-2 rounded-xl">Scade puncte</button>
                                </td>
                            )}
                            {id === 3 && (
                                <td className='border-b border-white px-4 py-2 text-md whitespace-pre-wrap'>
                                    <button onClick={() => handleInfo(row[0], "moreInfo")} className="bg-grayBTN text-white font-bold px-4 py-2 rounded-xl">More info</button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal show={showModal} onHide={() => setShowModal(false)} type={modalType} data={data} socket={socket} />
        </div>
    );
};

export default Table;