export function Title(props) {
    return (
        <div className={"text-white font-bold text-3xl lg:text-4xl text-center mt-4 mb-2 " + props.className}>
            {props.children}
        </div>
    );
}

export function Subtitle(props) {
    return (
        <p className={"text-white text-center " + props.className}>
            {props.children}
        </p>
    );
}

export function Para(props) {
    return (
        <p className={"text-white mt-4 mb-4 " + props.className}>
            {props.children}
        </p>
    );
}

export function ParaCenter(props) {
    return (
        <p className={"text-white text-center mt-4 mb-4 " + props.className}>
            {props.children}
        </p>
    );
}

export function Smalltext(props) {
    return (
        <p className={"text-white text-center " + props.className}>
            {props.children}
        </p>
    );
}

export function Smallertext(props) {
    return (
        <p className={"text-white text-center " + props.className}>
            {props.children}
        </p>
    );
}
