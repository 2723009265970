import {registerErrors, loginErrors, contactErrors} from "../Errors";
const isEmpty = require("is-empty");

function invalidEmail(email) {
    return !email.endsWith("@stud.acs.upb.ro");
}

function invalidURL(url) {
    try {
        new URL(url);
    } catch (e) {
        return true;
    }
    return false;
}

export function invalidData(data, type, password) {
    if (isEmpty(data)) {
        return true;
    }
    switch (type) {
        case "number":
            const numRegex =/^[0-9]+$/;
            return !numRegex.test(String(data.trim()));
        case "captain":
        case "name1":
        case "name2":
            return data.trim().split(" ").length < 2;
        case "teamName":
        case "message":
            return data.trim().length <= 0;
        case "link":
            return invalidURL(data.trim());
        case "email":
            return invalidEmail(data);
        case "phoneNumber":
            const regex=/^(\+((\d-)?|\d{1,3}))?0\d{3}[ -]?\d{3}[ -]?\d{3}$/;
            return !regex.test(String(data.trim()));
        case "password":
            return (data.length < 6) || (data.length > 30);
        case "confirmationPassword":
            return (data !== password);
        default:
            return false;
    }
}

function validateData(errors, entity, data, type, password) {
    return invalidData(data, type, password) ? errors[entity][type] : null;
}

function validateForm(data, entity, validationFunction) {
    let errors = {};
    Object.keys(data).forEach((key, _) => {
        const error = (key === "confirmationPassword")
            ? validationFunction(entity, data[key], key, data.password)
            : validationFunction(entity, data[key], key);
        if (!isEmpty(error)) {
            errors[key] = error;
        }
    });

    return errors;
}

export function validateRegisterData(entity, data, type, password) {
    return validateData(registerErrors, entity, data, type, password);
}

export function validateRegisterForm(data, entity) {
    return validateForm(data, entity, validateRegisterData);
}

export function validateLoginData(entity, data, type, password) {
    return validateData(loginErrors, entity, data, type, password);
}

export function validateLoginForm(data, entity) {
    return validateForm(data, entity, validateLoginData);
}

export function validateContactData(entity, data, type) {
    return validateData(contactErrors, entity, data, type);
}

export function validateContactForm(data, entity) {
    return validateForm(data, entity, validateContactData);
}


