import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import { sendAuthPostRequest } from "../helpers/api/Requests";
import qs from "qs";
import { Smalltext, Title } from "../section/sectionSubcomponents";
import { TextInput } from "../helpers/forms/inputs";
import Button from "../helpers/buttons";

export default function Login(props) {
  const [error, setError] = useState(false);
  const [formError, setFormError] = useState("");
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const navigate = useNavigate();

  const navigateToRegister = () => {
    // setError(true) //for disabling register.
    navigate("/register");
  }

  const hideModal = () => {
    setError(false);
  }

  return (
    <section id="loginContainer" className="w-full flex justify-center items-center">
      {error &&
        <div>
          <h2>A apărut o eroare</h2>
          <p>Perioada de înscriere s-a încheiat.</p>
          <button onClick={hideModal}>Close</button>
        </div>
      }
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          sendAuthPostRequest(`${process.env.REACT_APP_API_URL}/api/auth/login`, qs.stringify(values))
            .then(res => {
              props.loginCallback(res.data);
              navigate("/profile");
            })
            .catch((err) => {
              setFormError(err.response.data.email);
            })
        }}
      >
        {({ isSubmitting, handleSubmit }) => {
          return (
            isDesktop ?
              <div className="w-5/6 lg:w-2/3 flex shadow-2xl rounded-l-5xl">
                <div className="w-7/12 bg-generalBG rounded-l-5xl p-12">
                  <Title>Login</Title>
                  <form onSubmit={handleSubmit} className="pt-4">
                    <TextInput
                      label=""
                      name="email"
                      type="email"
                      placeholder="Email capitan"
                      style="register-input"
                      icon="mail"
                      inputGroupStyle="register-input-group"
                      iconStyle="register-icon"
                      popupForm
                    />
                    <TextInput
                      label=""
                      name="password"
                      type="password"
                      placeholder="Parola"
                      style="register-input"
                      icon="lock"
                      inputGroupStyle="register-input-group"
                      iconStyle="register-icon"
                      popupForm
                    />
                    {formError && <p className="error text-center text-red-500">{formError}</p>}
                    <div className="mt-2 flex justify-center items-center">
                      <Button type="submit" className="text-center font-bold text-black bg-white px-3 py-1 rounded-xl mt-2" name="white">Login</Button>
                    </div>
                  </form>
                  <Smalltext className="text-center text-white underline text-sm mt-4"><a className="hover:text-gray-400 " href="/reset-password">Ai uitat parola?</a></Smalltext>
                </div>
                <div className="w-5/12 bg-whiteImgBG bg-center bg-no-repeat bg-fixed bg-cover rounded-r-5xl flex justify-center items-center">
                  <div className="flex flex-col justify-center items-center">
                    <h2 className="text-black text-center text-3xl font-bold">Nu te-ai înscris încă?</h2>
                    <Button className="register-button bg-blackBTN text-white font-bold px-4 py-2 mt-4 rounded-xl" onClick={navigateToRegister}>Register</Button>
                  </div>
                </div>
              </div>

              :
              
              <div className="w-full p-6 mt-2">
                <Title>Login</Title>
                <form onSubmit={handleSubmit} className="pt-8">
                  <TextInput
                    label=""
                    name="email"
                    type="email"
                    placeholder="Email capitan"
                    icon="mail"
                    inputGroupStyle="register-input-group"
                    iconStyle="register-icon"
                    popupForm
                  />

                  <TextInput
                    label=""
                    name="password"
                    type="password"
                    placeholder="Parola"
                    icon="lock"
                    inputGroupStyle="register-input-group"
                    iconStyle="register-icon"
                    popupForm
                  />
                  <Smalltext classname="mt-4"><a className="hover:text-gray-400" href="/reset-password">Ai uitat parola?</a></Smalltext>
                  <div className="mt-2 flex justify-center items-center">
                    <Button type="submit" className="!px-10 !py-0 rounded-xl" name="white">Login</Button>
                  </div>
                </form>
                {formError && <div className="text-center"><p className="error text-red-500">{formError}</p></div>}
                
                <div className="flex flex-col justify-center items-center mt-20">
                  <Smalltext>Nu te-ai înscris încă?</Smalltext>
                  <Button className="!px-8 !py-0 rounded-xl" name="white" onClick={navigateToRegister}>Register</Button>
                </div>
              </div>
          )
        }
        }
      </Formik >
    </section >
  );
}
