export default function TitleBox({ title }) {
  let textColorClass = "text-white";

  if (title === "Sponsori Gold") {
      textColorClass = "text-yellow-400";
  } else if (title === "Sponsori Silver") {
      textColorClass = "text-gray-300";
  } else if (title === "Sponsori Bronze") {
      textColorClass = "text-[#CD7F32]";
  }

  return (
      <div className="md:bg-generalBG md:bg-opacity-80 py-1 rounded-2xl md:shadow-md mx-auto w-5/6">
          <h1 className={`text-4xl font-bold my-2 text-center md:text-5xl ${textColorClass}`}>
              {title}
          </h1>
      </div>
  );
};
