import React from "react";
import TextBox from "../../components/helpers/textBox";
import TitleBox from "../../components/helpers/titleBox";
import OfficialSponsor from "../../components/sponsor/officialSponsors";
import RegularSponsor from "../../components/sponsor/regularSponsors";
import Faq from "../../components/faq/Faq";
import ContactCard from "../../components/contactCard";
import landingData from "./landingPage.json";
import ScrollToHashElement from "../../components/helpers/scrollToHashElement";

export default function LandingPage() {
  const { textBox, titlebox, sponsoriGold, sponsoriSilver, sponsoriBronze, contacts } = landingData;

  return (
    <>
      <ScrollToHashElement />
      <div className="md:scroll-mt-28 scroll-mt-44" id="Perpetuum">
        <TextBox title={textBox[0].title} text={textBox[0].paragraphs} />
      </div>
      <div className="md:scroll-mt-28 scroll-mt-44" id="Reguli">
        <TextBox title={textBox[1].title} text={textBox[1].paragraphs} />
      </div>
      <div id="Sponsori" className="md:scroll-mt-28 scroll-mt-44 my-8 sm:my-24 mb-16">
        <TitleBox title={titlebox[0]} />
        {sponsoriGold.map((sponsor, index) => (
          <OfficialSponsor
            key={index}
            link={sponsor.link}
            description={sponsor.description}
            img={require(`../../assets/sponsors/${sponsor.imagePath}`)}
          />
        ))}
      </div>
      <TitleBox title={titlebox[1]} />
      <div className="flex justify-evenly items-center flex-col lg:flex-row">
        {sponsoriSilver.map((sponsor, index) => (
          <RegularSponsor

            key={index}
            link={sponsor.link}
            description={sponsor.description}
            img={require(`../../assets/sponsors/${sponsor.imagePath}`)}
          />
        ))}
      </div>
      <TitleBox title={titlebox[2]} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-around items-center">
        {sponsoriBronze.map((sponsor, index) => (
          <RegularSponsor
            key={index}
            link={sponsor.link}
            description={sponsor.description}
            img={require(`../../assets/sponsors/${sponsor.imagePath}`)}
            className="mb-4"
          />
        ))}
      </div>

      <div id="FAQ" className="md:scroll-mt-28 scroll-mt-44 my-8 sm:my-24 mb-16">
        <TitleBox title={titlebox[3]} />
        <Faq />
      </div>
      <div className="md:scroll-mt-28 scroll-mt-44" id="Contact">
        <TitleBox title={titlebox[4]} />
      </div>
      <div className="flex justify-center items-center">
        <ContactCard
          key={0}
          image={require(`../../assets/contact/${contacts[0].imagePath}`)}
          name={contacts[0].name}
          role={contacts[0].role}
          email={contacts[0].email}
          linkedin={contacts[0].linkedin}
          phoneNumber={contacts[0].phone}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {contacts.slice(1).map((contact, index) => (
          <div key={index + 1} className="flex justify-center items-center">
            <ContactCard
              image={require(`../../assets/contact/${contact.imagePath}`)}
              name={contact.name}
              role={contact.role}
              email={contact.email}
              linkedin={contact.linkedin}
              phoneNumber={contact.phone}
            />
          </div>
        ))}
      </div>

    </>
  );
}
