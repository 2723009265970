import React from 'react';
import SponsorModal from './sponsorModal';

export default function RegularSponsor({ link, description, img }) {
    let [showModal, setShowModal] = React.useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <div className="shadow-md rounded-xl bg-generalBG flex flex-col w-4/5 md:w-3/5 lg:w-3/5 min-h-80 justify-center items-center p-6 mx-auto my-6">
            <div className="flex items-center justify-center w-full h-40 md:h-48 lg:h-64 mb-4">
                <img
                    alt="Regular Sponsor"
                    src={img}
                    className="w-full h-full object-contain filter drop-shadow-md"
                />
            </div>
            <p className="text-sm text-gray-300 underline font-semibold cursor-pointer text-right" onClick={handleShow}>
                Afla mai multe
            </p>
            {showModal && (
                <SponsorModal
                    img={img}
                    description={description}
                    link={link}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};
