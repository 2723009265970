import React, { useState } from 'react';
import FAQS from './FaqData.json';

export default function FaqComponent() {
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownClick = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <div className="bg-generalBG rounded-5xl shadow-xl px-4 sm:px-8 py-8 w-5/6 sm:w-2/3 mx-auto my-8 sm:my-20">
      {FAQS.map((faq, index) => (
        <div key={index} className="mb-4">
          <div
            className={`cursor-pointer sm:px-4 py-2 flex items-center justify-between border-b-2 ${
              openDropdown === index ? 'border-gray-600' : 'border-white'
            }`}
            onClick={() => handleDropdownClick(index)}
          >
            <h2 className="text-white text-sm font-light sm:text-xl lg:text-2xl  mx-2 sm:mx-4 lg:mx-2">{`${index + 1}. ${faq.question}`}</h2>
            <span className="material-icons text-white">expand_more</span>
          </div>

          {openDropdown === index && (
            <div
              className="mt-2 bg-generalBG sm:px-4 py-2 overflow-hidden transition-all duration-500 border-b-2 border-white"
            >
              <p className="text-white text-sm font-light sm:text-xl lg:text-2xl  mx-2 sm:mx-4 lg:mx-2">{faq.response}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}