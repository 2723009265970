import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Smalltext, Title } from "../section/sectionSubcomponents";
import { sendAuthPostRequest } from "../helpers/api/Requests";
import * as Yup from "yup";
import { Formik } from "formik";
import qs from "qs";
import { TextInput } from "../helpers/forms/inputs";
import Button from "../helpers/buttons";

export default function ResetPassword(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState("")
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-row w-full justify-center">
                <div className="flex md:w-1/2 flex-col md:p-5 p-4 shadow-lg border-3xl bg-generalBG rounded-3xl">
                    <Title className="!text-2xl">Resetează parola</Title>
                    <Formik
                        initialValues={{
                            password: "",
                            confirmationPassword: "",
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            sendAuthPostRequest(`${process.env.REACT_APP_API_URL}/api/confirm/reset/${id}`, qs.stringify(values))
                                .then((response) => {
                                    setSubmitting(false);
                                    navigate('/login')
                                })
                                .catch((error) => {
                                    setSubmitting(false);
                                    setError(error.response.data.title)
                                })
                        }}
                        validationSchema={
                            Yup.object().shape({
                                password: Yup.string().min(8).max(100).required("Parola este obligatorie"),
                                confirmationPassword: Yup.string()
                                    .oneOf([Yup.ref("password")], "Parolele nu coincid")
                                    .required("Confirmarea parolei este obligatorie"),
                            })
                        }
                    >
                        {({ isSubmitting, handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex flex-col">
                                        <TextInput
                                            label=""
                                            name="password"
                                            type="password"
                                            placeholder="Parola"
                                            style="register-input"
                                            icon="lock"
                                            inputGroupStyle="register-input-group"
                                            iconStyle="register-icon"
                                        ></TextInput>
                                        <TextInput
                                            label=""
                                            name="confirmationPassword"
                                            type="password"
                                            placeholder="Confirmă parola"
                                            style="register-input"
                                            icon="lock"
                                            inputGroupStyle="register-input-group"
                                            iconStyle="register-icon"
                                        ></TextInput>
                                        <Button
                                            type="submit"
                                            className="register-button"
                                            disabled={isSubmitting}
                                            name="white"
                                        >
                                            Resetează parola
                                        </Button>
                                        {error && <p className="py-4 text-red-500">{error}</p>}
                                    </div>
                                    
                                </form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
}