import Linkedin from '../assets/contact/linkedin.png';

export default function Card({ image, name, role, email, linkedin, phoneNumber }) {

    const isManagerProiect = role === '-Manager Proiect-';
    const widthClass = isManagerProiect ? 'w-11/12 md:w-2/5 xl:w-1/4' : 'w-11/12 md:w-4/5';

    return (
        <div className={`${widthClass} md:w bg-generalBG rounded-5xl shadow-2xl my-8 sm:my-12 flex flex-col justify-center items-center p-4`}>
            <div className='w-5/6'>
                <img className="rounded-full" src={image} alt="" />
            </div>
            <div className="flex flex-col justify-center items-center mt-8">
                <div className="flex items-center">
                    <a href={linkedin} target="_blank" rel="noreferrer">
                        <img className="h-6 mb-4 md:ml-3" src={Linkedin} alt="LinkedIn"/>
                    </a>
                    <h5 className="mb-4 ml-1 md:ml-3 text-lg md:text-2xl font-bold tracking-tight text-white text-center">{name}</h5>
                </div>
                <p className="text-md md:text-lg lg:text-xl py-2 font-medium text-white">{role}</p>
                <a href={`mailto:${email}`} className="text-md md:text-lg px-3 py-2 font-medium text-center text-white">
                    {email}
                </a>
                <a href={`tel:${phoneNumber}`} className="text-md md:text-lg px-3 py-2 font-medium text-center text-white">
                    {phoneNumber}
                </a>
            </div>
        </div>
    );
}