import React from "react";
import ConfirmationModal from "../modals/confirmationModal";
import Landing from "../../pages/LandingPage/landingPage";
import { useParams } from "react-router-dom";

export default function EmailConfirmation(props) {
  const { id } = useParams();
  return (
        <div>
          <Landing />
          <ConfirmationModal {...props} id={id} url={`${process.env.REACT_APP_API_URL}/api/confirm/email`}  redirectTo="/" />
        </div>
    )
}