import { ErrorMessage, Field } from "formik";

export function TextInput({
    label,
    name,
    type,
    placeholder,
    icon,
    iconStyle
}) {
    return (
        <div className="mb-4">
            <label className="block mb-2 w-full font-bold text-white">{label}</label>
            <div className="border-b border-white flex">
                {icon && <span className={`mr-4 material-icons text-white self-end ${iconStyle}`}>{icon}</span>}
                <Field
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    className={` w-full bg-transparent md:bg-generalBG text-white md:w-4/5 font-bold overflow-hidden text-xs md:text-base lg:text-lg`}
                />
            </div>
            <ErrorMessage component="div" name={name} className="text-red-500" />
        </div>
    );
}

export function TextInputItems({
    label,
    name,
    type,
    placeholder,
    icon,
    iconStyle
}) {
    return (
        <div className="mb-4">
            <label className="block mb-2 w-full text-white">{label}</label>
            <div className="border-b border-white flex">
                {icon && <span className={`mr-4 material-icons text-white self-end ${iconStyle}`}>{icon}</span>}
                <Field
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    className={` w-full bg-transparent bg-[#545454] text-white md:w-4/5 font-bold overflow-hidden text-xs md:text-base lg:text-lg`}
                />
            </div>
            <ErrorMessage component="div" name={name} className="text-red-500" />
        </div>
    );
}

export function SelectInput({ name, children }) {
    return (
        <div>
            <Field as="select" name={name} className="block w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:border-indigo-500">
                {children}
            </Field>
            <ErrorMessage component="div" name={name} className="text-red-500" />
        </div>
    );
}

export function FileInput({ label, setFieldValue, name }) {
    return (
        <div className="mb-4 overflow-hidden">
            <label className="block text-white text-center font-bold mb-4">{label}</label>
            <input
                type="file"
                name={name}
                accept="application/pdf"
                onChange={(event) => {
                    setFieldValue(name, event.currentTarget.files?.[0]);
                }}
                className="text-white text-sm md:text-base lg:text-lg rounded-md shadow-sm"
            />
            <ErrorMessage component="div" name={name} className="text-red-500" />
        </div>
    );
}

export function PictureInput({ label, setFieldValue, name }) {
    return (
        <div className="mb-4 overflow-hidden">
            <label className="block text-white font-bold mb-4">{label}</label>
            <input
                type="file"
                name={name}
                accept="image/*"
                onChange={(event) => {
                    setFieldValue(name, event.currentTarget.files?.[0]);
                }}
                className="text-white text-sm md:text-base lg:text-lg rounded-md shadow-sm"
            />
            <ErrorMessage component="div" name={name} className="text-red-500" />
        </div>
    );
}