import React, { useState } from 'react';
import Button from '../helpers/buttons';
import { useMediaQuery } from 'react-responsive';

const Wood = ({ item, selectedItem, showDetails, handleClick, handleDropdownToggle, teamName }) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <div key={item.teamId}>
            <div className={`flex justify-between py-2 ${!(selectedItem && selectedItem.itemId === item.itemId) ? 'border-b border-white' : ''}`}>
                <div className={`px-2 py-1 ${isDesktop ? 'text-lg' : 'text-sm'}`}>{teamName}</div>
                {!showDropdown && (
                    <div className="px-2 py-1 justify-self-center">
                        <Button className={`${isDesktop ? 'text-lg' : 'text-sm'}`} id={`button-${item.itemId}`} name='black' onClick={() => handleClick(item)}>
                            {item.isDone ? 'Done' : 'Pending'}
                        </Button>
                    </div>)}
                <div className="flex px-2 py-1">
                    <button onClick={toggleDropdown}>
                        <svg className={`h-4 w-4 md:h-5 md:w-5 text-white-800 ${selectedItem && selectedItem.itemId === item.itemId && showDetails ? 'transform rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>
            </div>

            {showDropdown && (
                <div className={`flex flex-col w-full border-b border-white ${isDesktop ? '' : 'bg-generalBG'}`}>
                    <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'} p-3 items-center ${isDesktop ? '' : 'bg-generalBG'}`}>
                        <img src={item.file} alt="Imagine" className={`py-1 ${isDesktop ? 'max-h-52' : 'max-h-32'}`} />
                        <p className={`flex justify-center px-4 py-1 text-white-700 text-center flex-grow ${isDesktop ? '' : 'text-xs'}`}>{item.description}</p>
                    </div>
                    <div className={`flex justify-center px-4 pb-3 ${isDesktop ? '' : 'text-sm'} justify-self-center`}>
                        <Button id={`button-${item.itemId}`} name='black' onClick={() => handleClick(item)}>
                            {item.isDone ? 'Done' : 'Pending'}
                        </Button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Wood;
