import Auth from "../../../modules/Auth";
const axios = require("axios");
const qs = require("qs");

export function sendPostRequest(url, data, token) {
    return axios({
        method: "post",
        url: url,
        data: data,
        headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            "Authorization": `Bearer ${token}`
        }
    })
}

export function sendAuthPostRequest(url, data) {
    return sendPostRequest(url, data, Auth.getToken())
}

export function sendGetRequest(url, data, token) {
    return axios({
        method: "get",
        url: url,
        data: qs.stringify(data),
        headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            "Authorization": `Bearer ${token}`
        }
    });
}

export function sendAuthGetRequest(url, data) {
    return sendGetRequest(url, data, Auth.getToken());
}