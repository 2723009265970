import React from "react";
import Button from "../helpers/buttons";

function CenteredModal(props) {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-generalBG rounded-lg p-8 max-w-md w-full">
                <div className="text-center mb-8">
                    <p className="text-white text-lg font-semibold">{props.title}</p>
                </div>
                <div className="mb-8 text-center">
                    <p className="text-white font-medium">{props.body}</p>
                </div>
                <div className="text-center">
                    <Button name="black" className="modal-button" onClick={props.onClick}>
                        <h3 className="font-semibold text-white">Închide</h3>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default CenteredModal;
