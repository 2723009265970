import SectionHeader from "../../components/section/sectionHeader";
import { useState, useEffect } from "react";
import { sendAuthGetRequest } from "../../components/helpers/api/Requests";
import Modal from "../../components/modals/modals";

import io from "socket.io-client";
import WSHelper from '../../Websockets/Websockets'

export default function InventoryPage({ card: MarketCard, url: url }) {

  const [items, setItems] = useState([]);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [loading, setLoading] = useState(true);
  const [socket, setSocket] = useState(io);
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);

  const messageSetter = (message) => {
    setMessage(message);
    setShow(true);
  }
  const successSeter = (suc) => {
    setSuccess(suc);
  }
  const itemSetter = (items) => {
    setItems(items)
  }
  const pointsSetter = (points) => {
    setCurrentPoints(points)
  }

  WSHelper('users', { "message": messageSetter, "success": successSeter, "myMarket": itemSetter, "points": pointsSetter }, setSocket);

  useEffect(() => {
    sendAuthGetRequest(`${process.env.REACT_APP_API_URL}/api/items/${url}`, null)
      .then(res => {
        setItems(res.data.items);
        setCurrentPoints(res.data.points)
        setLoading(false);
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('updatePrices', async (msg) => {
        sendAuthGetRequest(`${process.env.REACT_APP_API_URL}/api/items/${url}`, null)
          .then(res => {
            setItems(res.data.items);
          })
          .catch((err) => { });
      });
    }
  }, [socket]);

  const closeModal = () => {
    setShow(false);
    setTimeout(() => {
      setMessage({});
    }, 500)

  }
  return (
    <>
      <div className="py-10 min-h-screen">
        <SectionHeader><p className="text-5xl md:text-5xl">My Items</p><br /><p className="text-xl md:text-2xl">Points: {currentPoints}</p></SectionHeader>
        {loading && <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900" />}
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {items.map((item, index) =>
            item.name !== "Lemn" && (
              <div key={index} className="flex justify-center mt-8">
                <MarketCard
                  _id={item._id}
                  socket={socket}
                  key={index}
                  priceChange={item.priceChange}
                  sellPrice={item.sell}
                  capacity={item.capacity}
                  objectName={item.name}
                  image={item.filePath}
                />
              </div>
            )
          )}
        </div>
      </div>
      <Modal show={show} onHide={closeModal} type={success ? "success" : "error"} text={message} />
    </>
  );
}

