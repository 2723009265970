import React from "react";
import Login from "../../components/auth/login";

function LoginPage({ loginCallback }) {

  return (
    <div className="w-full h-[95vh] flex justify-center items-start sm:items-center">
      <Login loginCallback={loginCallback} />
    </div>
  );
}
export default LoginPage;
