import React, { useState } from 'react';
import brand from "../assets/brand.png";
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

function Navbar(props) {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <nav className="bg-generalBG w-full p-4 shadow-lg sticky top-0 z-20">
            <div className="w-full mx-auto flex justify-between items-center">
                {/* Logo with reload on click */}
                <a className="text-white sm:ml-8 font-bold cursor-pointer" href='/'>
                    <img src={brand} className='h-10' alt="Logo" />
                </a>

                {/* Mobile Menu Button */}
                <button
                    className="lg:hidden text-white text-3xl focus:outline-none"
                    onClick={toggleMobileMenu}
                >

                    <span>&#9776;</span>
                </button>
                {/* Mobile Menu */}
                <div
                    className={`${isMobileMenuOpen
                        ? 'transform translate-x-0'
                        : 'transform translate-x-full'
                        } lg:translate-x-0 lg:hidden fixed top-0 right-0 w-1/2 h-full bg-generalBG z-50 transition-transform duration-300 ease-in-out`}
                >
                    <ul className="flex flex-col items-center justify-center">
                        <li className="w-full mb-4">
                            <button
                                className="lg:hidden text-white focus:outline-none text-5xl"
                                onClick={closeMobileMenu}
                            >
                                &times;
                            </button>
                        </li>
                        {props.isAdmin && (
                            <li className='mb-4'>
                                <Link to="/admin" onClick={closeMobileMenu} className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                    <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                        Admin
                                    </span>
                                </Link>
                            </li>
                        )}
                        <li className='mb-4'>
                            <Link to="/#Perpetuum"
                                onClick={closeMobileMenu}
                                className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Acasa
                                </span>
                            </Link>
                        </li>
                        <li className='mb-4'>
                            <a href="https://chat.whatsapp.com/CAPqN2zyzRoCYNG8xgQHVc" className='text-white text-2xl' onClick={closeMobileMenu}>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Cauta Echipa
                                </span>
                            </a>
                        </li>

                        <li className='mb-4'>
                            <Link to="/#Reguli" onClick={closeMobileMenu} className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Reguli
                                </span>
                            </Link>
                        </li>
                        <li className='mb-4'>
                            <Link to="/#Sponsori" onClick={closeMobileMenu} className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Sponsori
                                </span>
                            </Link>
                        </li>
                        <li className='mb-4'>
                            <Link to="/#FAQ" onClick={closeMobileMenu} className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    FAQ
                                </span>
                            </Link>
                        </li>
                        <li className='mb-4'>
                            <Link to="/#Contact" onClick={closeMobileMenu} className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Contact
                                </span>
                            </Link>
                        </li>
                        {props.isAuthenticated && (
                            <li className='mb-4'>
                                <Link to="/profile" onClick={closeMobileMenu} className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                    <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                        Profile
                                    </span>
                                </Link>
                            </li>
                        )}
                        {!props.isAuthenticated ? (
                            <li className='mb-4'>
                                <Link to="/login" onClick={closeMobileMenu} className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                    <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                        Login
                                    </span>
                                </Link>
                            </li>
                        ) :
                            (<li className='mb-4'>
                                <button onClick={() => { props.logoutCallback(); closeMobileMenu(); }} className='group text-white transition-all duration-300 ease-in-out text-2xl'>
                                    <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                        Logout
                                    </span>
                                </button>
                            </li>
                            )}
                    </ul>
                </div>

                {/* Desktop Menu */}
                <div className="hidden lg:flex lg:items-center lg:w-auto mr-8">
                    <ul className="lg:flex items-center justify-between text-xl text-white">
                        {props.isAdmin && (
                            <li className='pl-4'>
                                <Link to='/admin' className='group text-white transition-all duration-300 ease-in-out' >
                                    <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                        Admin
                                    </span>
                                </Link>
                            </li>
                        )}
                        <li className='pl-4'>
                            <Link to="/#Perpetuum" className='group text-white transition-all duration-300 ease-in-out'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Acasa
                                </span>
                            </Link>
                        </li>
                        <li className='pl-4'>
                            <a href="https://chat.whatsapp.com/CAPqN2zyzRoCYNG8xgQHVc" className='text-white' >
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Cauta Echipa
                                </span>
                            </a>
                        </li>
                        <li className='pl-4'>
                            <Link to="/#Reguli" className='group text-white transition-all duration-300 ease-in-out'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Reguli
                                </span>
                            </Link>
                        </li>
                        <li className='pl-4'>
                            <Link to="/#Sponsori" className='group text-white transition-all duration-300 ease-in-out'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Sponsori
                                </span>
                            </Link>
                        </li>
                        <li className='pl-4'>
                            <Link to="/#FAQ" className='group text-white transition-all duration-300 ease-in-out'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    FAQ
                                </span>
                            </Link>
                        </li>
                        <li className='pl-4'>
                            <Link to="/#Contact" className='group text-white transition-all duration-300 ease-in-out'>
                                <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                    Contact
                                </span>
                            </Link>
                        </li>
                        {props.isAuthenticated && (
                            <li className='pl-4'>
                                <Link to="/profile" className='group text-white transition-all duration-300 ease-in-out'>
                                    <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                        Profile
                                    </span>
                                </Link>
                            </li>
                        )}
                        {!props.isAuthenticated ? (
                            <li className='pl-4'>
                                <Link to='/login' className='group text-white transition-all duration-300 ease-in-out'>
                                    <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                        Login
                                    </span>
                                </Link>
                            </li>
                        )
                            :
                            (<li className='pl-4'>
                                <button onClick={props.logoutCallback} className='group text-white transition-all duration-300 ease-in-out'>
                                    <span className='bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
                                        Logout
                                    </span>
                                </button>
                            </li>
                            )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
