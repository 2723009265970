import React, { useState } from "react";
import Button from "../helpers/buttons";
import { TextInput, TextInputItems, PictureInput } from "../helpers/forms/inputs";
import Input from "../helpers/forms/input";
import { invalidData } from "../helpers/api/Validate";
import person from "../../assets/profile/player-icon.png";
import { Formik } from "formik";
import * as Yup from "yup";
import qs from "qs";
import { sendAuthPostRequest } from "../helpers/api/Requests";
const isEmpty = require("is-empty");

const Modal = ({ show, onHide, type, data, socket }) => {

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

  const [formData, setFormData] = useState({
    productName: "",
    sell: "",
    buy: "",
    stock: "",
  });
  let [points, setPoints] = useState("");
  const [error, setError] = useState("");
  const onChange = (e) => {
    setPoints(e.target.value);
  }

  const submit = (e) => {
    e.preventDefault();
    setError(invalidData(points, "number") ? "You must type a number." : "");
    if (invalidData(points, "number")) {
      console.log("You must type a number.")
      console.log(points)
    }

    if (!isEmpty(error)) {
      return;
    }
    if (invalidData(points, "number") === false) {
      if (type === "addPoints")
        socket.emit('modifyPoints', { id: data._id, amount: points })
      else if (type === "removePoints")
        socket.emit('modifyPoints', { id: data._id, amount: -points })
      setPoints("");
      setError("");
      onHide();
    }
  }

  const closeModal = () => {
    setPoints("");
    setError("");
    onHide();
  }

  const InputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const addItemSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return show ? (
    <div className="w-full fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25">
      <div className={`lg:w-7/12 sm:w-3/4 w-full mx-2 sm:mx-0 rounded-xl bg-[#545454] shadow-lg flex flex-col justify-center`}>
        {type === "addPoints" || type === "removePoints" ? (
          <form onSubmit={submit}>
            <div className="flex flex-col justify-center py-12 px-5 md:px-10">
              <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-x-16 mt-8">
                <p className="w-full lg:w-1/2 font-bold text-3xl text-center lg:text-start mb-4 lg:mb-0" children="How many points?" />
                <Input value={points} onChange={onChange} />
              </div>
              {error && <p className="text-red-500 text-center mb-4 mt-2 text-2xl" children={error} />}
              <div className="flex flex-col lg:flex-row items-center justify-center gap-x-16 gap-y-4 my-8">
                <Button type="submit" children="Proceed" name="admin" />
                <Button onClick={closeModal} children="Exit" name="admin" />
              </div>
            </div>
          </form>
        ) : type === "moreInfo" ? (
          <>
            <div className="w-full py-10 flex flex-col items-center justify-center">
              <div classsName="flex flex-col items-center justify-center gap-y-4">
                <p className="font-bold text-2xl sm:text-3xl lg:text-4xl text-center mb-4" children={data.name} />
                <p className="text-lg sm:text-xl text-center" children={data.email} />
                <p className="text-lg sm:text-xl text-center" children={data.phoneNumber} />
              </div>
              <div className="w-full flex flex-col items-center justify-center mt-6 px-5 sm:px-10 md:px-15 lg:px-20 xl:px-28">
                {
                  data.playerIds.map((player) => {
                    return (
                      <div className="w-full flex md:flex-row flex-col items-center justify-between py-3">
                        <div className="flex flex-row items-center justify-center">
                          <img src={person} alt="Ti-a cazut satelitul o_o" className='w-6 h-6 justify-center mr-4' />
                          <p className="font-bold text-xl sm:text-2xl lg:text-3xl flex items-center" children={player.name} />
                        </div>
                        <a href={player.cv} className="text-lg sm:text-xl lg:text-2xl flex items-center">Link CV {player.name}</a>
                      </div>
                    )
                  })
                }
              </div>
              <div className="flex flex-col items-center">

                <Button onClick={onHide} children="Exit" name="admin" />
              </div>
            </div>

          </>
        ) : type === "addItem" ? (
          <div className="flex w-full justify-center items-center">
            <div className="w-full lg:py-14 py-6">
              <p className="w-full font-extrabold text-3xl lg:text-5xl flex items-center justify-center mb-6 lg:mb-16 text-white" children="Add New Item" />
              <Formik
                initialValues={{
                  name: "",
                  itemImg:"",
                  buy: "",
                  sell: "",
                  capacity: "",
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Required"),
                  itemImg: Yup.mixed().required("Required").test("fileSize", "File too large", (value) => {
                    if (value) {
                      return value.size <= 2000000;
                    }
                    return true;
                  }).test("fileFormat", "Unsupported Format", (value) => {
                    if (value) {
                      return SUPPORTED_FORMATS.includes(value.type);
                    }
                    return true;
                  }),
                  buy: Yup.number("Numar!").required("Required"),
                  sell: Yup.number("Numar!").required("Required"),
                  capacity: Yup.number("Numar!").required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values)
                  const form = new FormData();
                  form.append("name", values.name);
                  form.append("buy", values.buy);
                  form.append("sell", values.sell);
                  form.append("capacity", values.capacity);
                  form.append("itemImg", values.itemImg);
                  sendAuthPostRequest(`${process.env.REACT_APP_API_URL}/api/admin/add-item`, form)
                    .then((response) => {
                      console.log(response)
                      setError("");
                      onHide();
                    })
                    .catch((err) => {
                      console.log(err)
                      setError(err.response.data);
                    })
                }}
              >
                {({ isSubmitting, handleSubmit, setFieldValue }) => (
                  <form className="w-full flex lg:flex-row flex-col" onSubmit={handleSubmit}>
                    <div className="lg:w-2/3 w-full flex flex-row items-center justify-center px-8">
                      <div className="w-full flex flex-col items-center justify-center gap-y-4">
                        <div className="w-full flex lg:flex-row flex-col items-center justify-between">
                          <p className="lg:w-1/2 font-bold text-xl sm:text-2xl lg:text-3xl flex items-center text-white" children="Product Name" />
                          <TextInputItems
                            label=""
                            name="name"
                            type="text"
                            placeholder="Item name"
                            popupForm
                            inputGroupClassName="flex"
                        />
                        </div>
                        <div className="w-full flex lg:flex-row flex-col items-center justify-between">
                          <p className="lg:w-1/2 font-bold text-xl sm:text-2xl lg:text-3xl flex items-center text-white" children="Image" />
                          <PictureInput
                            label=""
                            name="itemImg"
                            type="file"
                            placeholder="Image"
                            popupForm
                            inputGroupClassName="flex"
                            setFieldValue={setFieldValue}
                        />
                        </div>
                        <div className="w-full flex lg:flex-row flex-col items-center justify-between">
                          <p className="lg:w-1/2 font-bold text-xl sm:text-2xl lg:text-3xl flex items-center text-white" children="Buy" />
                          <TextInputItems
                            label=""
                            name="buy"
                            type="text"
                            placeholder="Buy price"
                            popupForm
                            inputGroupClassName="flex"
                        />
                        </div>
                        <div className="w-full flex lg:flex-row flex-col items-center justify-between">
                          <p className="lg:w-1/2 font-bold text-xl sm:text-2xl lg:text-3xl flex items-center text-white" children="Sell" />
                          <TextInputItems
                            label=""
                            name="sell"
                            type="text"
                            placeholder="Sell price"
                            popupForm
                            inputGroupClassName="flex"
                        />
                        </div>
                        <div className="w-full flex lg:flex-row flex-col items-center justify-between">
                          <p className="lg:w-1/2 font-bold text-xl sm:text-2xl lg:text-3xl flex items-center text-white" children="Stock" />
                          <TextInputItems
                            label=""
                            name="capacity"
                            type="text"
                            placeholder="Capacity"
                            popupForm
                            inputGroupClassName="flex"
                        />
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-1/3 w-full flex flex-col items-center justify-center gap-y-10 lg:mt-0 mt-6">
                      <Button type="submit" children="Proceed" name="admin" />
                      <Button onClick={onHide} children="Exit" name="admin" />
                      {error && <p className="text-red-500 mt-2">{error}</p>}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        ) : null}
      </div>
    </div >
  ) : null;
};

export default Modal;