import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendAuthPostRequest } from "../helpers/api/Requests";
import { Formik } from "formik";
import qs from "qs";
import { Title } from "../section/sectionSubcomponents";
import { TextInput } from "../helpers/forms/inputs";
import Button from "../helpers/buttons";

export default function ResetPasswordMail(props) {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex flex-row w-full justify-center">
                <div className="flex md:w-1/2 flex-col md:p-5 p-4 shadow-lg border-3xl bg-generalBG rounded-3xl">
                    <Title className="!text-2xl">Resetează parola</Title>
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            sendAuthPostRequest(`${process.env.REACT_APP_API_URL}/api/auth/send-reset-mail`, qs.stringify(values))
                                .then((response) => {
                                    setSubmitting(false);
                                    navigate('/login')
                                })
                                .catch((error) => {
                                    setSubmitting(false);
                                    setError(error.response.data.body);
                                })
                        }}
                    >
                        {({ isSubmitting, handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="flex flex-col mt-4">
                                        <TextInput
                                            label=""
                                            name="email"
                                            type="email"
                                            placeholder="Email"
                                            icon="mail"
                                            inputGroupStyle="register-input-group"
                                            iconStyle="register-icon"
                                            popupForm
                                        />
                                        <div className="mt-2 flex justify-center">
                                            <Button type="submit" name="white" className="mt-2 mb-2">
                                                Send Mail
                                            </Button>
                                        </div>
                                        {error && <div className="py-4 text-red-500 ps-2 error">{error}</div>}
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
}