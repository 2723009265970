import Table from '../../components/admin/table';
import TitleBox from '../../components/helpers/titleBox';
import Button from '../../components/helpers/buttons';
import { sendAuthGetRequest } from '../../components/helpers/api/Requests';
import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Auth from '../../modules/Auth';
import Modal from '../../components/modals/addModal';
import WSHelper from '../../Websockets/Websockets';

const AdminMarket = () => {
    const id = 2;
    const headers = ['Product Name', 'Sell', 'Buy', 'Stock', 'Add', 'Remove'];
    const [error, setError] = useState("");
    const [items, setItems] = useState([]);
    const [socket, setSocket] = useState(io);
    const [refresh, setRefresh] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        sendAuthGetRequest(`${process.env.REACT_APP_API_URL}/api/admin/list-items`, null)
            .then(res => {
                console.log(res.data);
                itemSetter(res.data);
            })
            .catch((err) => setError(err));
    }, [refresh]);

    const itemSetter = (items) => {
      const mappedItems = items.map(item => [
        item._id,
        item.name,
        item.sell.toString(),
        item.buy.toString(),
        item.capacity.toString()
      ]);
      setItems(mappedItems);
    };

    WSHelper('admins', { "market": itemSetter }, setSocket);

    return (
        <div className="w-full min-h-screen flex flex-col justify-start items-center py-6 md:mt-12 mt-6">
            <TitleBox title="PRODUCTS" />
            <div className='mx-4 sm:mx-0 w-5/6 bg-generalBG md:p-12 p-4 rounded-5xl shadow-2xl flex flex-col md:my-8 my-4'>
                <Table id={id} headers={headers} rows={items} socket={socket} setSocket={setSocket}/>
                <Button className="text-center font-bold px-3 py-1 rounded-xl mt-2 mx-2 w-1/2 lg:w-1/5 text-xs md:text-xl" name="gray" onClick={() => setShowModal(!showModal)}>
                    Add New Item
                </Button>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} type="addItem" />
        </div>
    );
};

export default AdminMarket;
