import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Auth from "./modules/Auth";

import Navbar from "./components/navbar";
import NavProvider from "./modules/context/NavContext";
import LandingPage from "./pages/LandingPage/landingPage";
import LoginPage from "./pages/LoginPage/loginPage";
import Register from "./pages/RegisterPage/registerPage";
import Table from "./pages/Admin/admin";
import AdminMarket from "./pages/Admin/admin_market";
import AdminTransaction from "./pages/Admin/admin_transaction";
import ResetPasswordMail from "./components/auth/resetPassMail";
import ResetPassword from "./components/auth/resetPass";
import EmailConfirmation from "./components/auth/confirmation";
import Profile from "./pages/ProfilePage/profilePage";
import Market from "./pages/MarketPage/marketPage";
import MarketCard from "./components/helpers/shopObject";
import InventoryPage from "./pages/InventoryPage/inventoryPage";
import Mesteri from "./pages/Admin/admin_mesteri";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const toggleAuthStatus = async () => {
    setIsAuthenticated(Auth.isUserAuthenticated());
  }

  const loginCallback = (token) => {
    Auth.authenticateUser(token);
    toggleAuthStatus();
  }

  const logoutCallback = () => {
    Auth.deauthenticateUser();
    toggleAuthStatus();
  }

  useEffect(() => {
    toggleAuthStatus();
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      setIsAdmin(Auth.isAdmin());
    }
  }, [isAuthenticated])

  return (
    <div className="relative">
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-mobileImgBG md:bg-blackImgBG bg-center bg-cover bg-no-repeat"/>
      </div>
      <div className="relative z-10">
        <NavProvider>
          <BrowserRouter>
            <Navbar isAuthenticated={isAuthenticated} isAdmin={isAdmin} logoutCallback={logoutCallback} />
            <Routes>
              { isAuthenticated && (
                <>
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/profile/market" element={<Market card={MarketCard} url={"list-items"} />} />
                  <Route path="/profile/my-items/" element={<InventoryPage card={MarketCard} url={"my-items"} />} />
                  <Route path="/admin" element={<Table />} />
                  <Route path="/admin/market" element={<AdminMarket />} />
                  <Route path="/admin/mesteri" element={<Mesteri />} />
                  <Route path="/admin/transaction" element={<AdminTransaction />} />
                </>
              )}
              { !isAuthenticated && (
                <>
                  <Route path="/login" element={<LoginPage loginCallback={loginCallback} />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/reset-password" element={<ResetPasswordMail />} />
                  <Route path="/reset/email/:id" element={<ResetPassword />} />
                  <Route path="/confirm/email/:id" element={<EmailConfirmation />} />
                </>
              )}
              <Route path="*" element={<LandingPage isAuthenticated={isAuthenticated} loginCallback={loginCallback} logoutCallback={logoutCallback} />} />
            </Routes>
          </BrowserRouter>
        </NavProvider>
      </div>
    </div>
  )
}

export default App;
