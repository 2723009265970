import React from "react";

export default function JumboSection(props) {
  return (
    <div className={"container mx-auto " + props.className}>
      <div className="flex justify-center items-center min-h-screen">
        <div className="lg:w-4/6 w-full md:bg-generalBG md:rounded-3xl md:shadow-lg p-6">
          {props.children}
        </div>
      </div>
    </div>
  );
}
