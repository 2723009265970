import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { sendPostRequest } from '../helpers/api/Requests';
import { FileInput } from '../helpers/forms/inputs';
import { Smalltext } from '../section/sectionSubcomponents';
import Button from '../helpers/buttons';
import { Title } from '../section/sectionSubcomponents';

const EditCV = ({ team, show, setShow }) => {
  const [values, setValues] = useState({})
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)

  const FILE_SIZE = 10 * 1000000;
  const SUPPORTED_FORMATS = ["application/pdf", undefined];

  const validationSchema = Yup.object().shape({
    cvCap: Yup.mixed()
      .nullable()
      .test(
        "fileFormat",
        "CV-ul trebuie sa fie in format PDF",
        value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        "fileSize",
        "CV-ul trebuie sa aiba maxim 160KB",
        value => !value || (value && value.size <= FILE_SIZE)
      ),
    cv1: Yup.mixed()
      .nullable()
      .test(
        "fileFormat",
        "CV-ul trebuie sa fie in format PDF",
        value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        "fileSize",
        "CV-ul trebuie sa aiba maxim 160KB",
        value => !value || (value && value.size <= FILE_SIZE)
      ),
    cv2: Yup.mixed()
      .nullable()
      .test(
        "fileFormat",
        "CV-ul trebuie sa fie in format PDF",
        value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        "fileSize",
        "CV-ul trebuie sa aiba maxim 160KB",
        value => !value || (value && value.size <= FILE_SIZE)
      )
  })

  useEffect(() => {
    function handleClickOutside(event) {
      if (show && !event.target.closest(".modal-content")) {
        setShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, setShow]);

  return (
    <>
      {show && (
        <div className="fixed inset-0 items-center justify-center bg-black bg-opacity-50">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-generalBG mt-20 md:mt-0 rounded-lg text-center p-8 w-11/12 md:w-2/3 xl:w-2/5 mx-auto modal-content">
              <div className="flex justify-end">
                <button onClick={() => setShow(false)} className="text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="mb-6">
                <Title className="md:text-3xl text-xl">Editeaza CV-ul!</Title>
              </div>

              <Formik
                initialValues={{
                  cvCap: null,
                  cv1: null,
                  cv2: null,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setMessage(null)
                  setError(null)
                  setSubmitting(true);
                  const form = new FormData();
                  if (!values.cvCap && !values.cv1 && !values.cv2) {
                    setError("Nu ai selectat niciun CV!")
                    setMessage(null)
                    setSubmitting(false)
                    return;
                  }
                  form.append("cvCap", values.cvCap);
                  form.append("cv1", values.cv1);
                  form.append("cv2", values.cv2);
                  sendPostRequest(`${process.env.REACT_APP_API_URL}/api/teams/edit-cv`, form, localStorage.getItem("token"))
                    .then((res) => {
                      setSubmitting(false);
                      setError(null)
                      setMessage(res.data)
                    })
                    .catch((err) => {
                      setSubmitting(false);
                      setMessage(null)
                      setError(err.response.data.message)
                    });
                  setValues(values);
                }}
              >
                {({ isSubmitting, handleSubmit, setFieldValue }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="pt-5 pb-5">
                        <div className="flex flex-col">
                          <FileInput
                            label={`${team.players[0]}'s CV`}
                            name="cvCap"
                            setFieldValue={setFieldValue}
                            className="my-4"
                          />
                          <FileInput
                            label={`${team.players[1]}'s CV`}
                            name="cv1"
                            setFieldValue={setFieldValue}
                            className="my-4"
                          />
                          <FileInput
                            label={`${team.players[2]}'s CV`}
                            name="cv2"
                            setFieldValue={setFieldValue}
                            className="my-4"
                          />
                        </div>
                      </div>
                      <div className="pt-5 border-0">
                        <div>
                          <Smalltext className="text-xs md:text-sm xl:text-base mb-5"> *Se pot introduce doar CV-urile care se vor schimba</Smalltext>
                          <Button name="white" type="submit">
                            <h3 className="font-bold">Trimite</h3>
                          </Button>
                          {error && <p className="py-4 text-red-500">{error}</p>}
                          {message && <p className="py-4 text-green-500">{message}</p>}
                          <div className="mt-5">
                            {isSubmitting ? <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div> : null}
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </Formik>

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EditCV;
