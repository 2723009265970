import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Formik } from "formik";
import * as Yup from "yup";
import JumboSection from "../section/jumboSection";
import { FileInput, TextInput } from "../helpers/forms/inputs";
import { Title } from "../section/sectionSubcomponents";
import Button from "../helpers/buttons";
import { sendAuthPostRequest } from "../helpers/api/Requests";

export default function Register() {

    const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
    const [values, setValues] = useState({});
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const FILE_SIZE = 10 * 1000000;
    const SUPPORTED_FORMATS = ["application/pdf"];

    const validationSchema = Yup.object().shape({
        teamName: Yup.string().max(100).required("Numele echipei este obligatoriu"),
        captain: Yup.string()
            .max(100)
            .required("Numele capitanului este obligatoriu")
            .notOneOf([Yup.ref("name1"), Yup.ref('name2')], "Numele trebuie sa fie unic fata de coechipieri"),
        name1: Yup.string()
            .max(100)
            .required("Numele membrului 1 este obligatoriu")
            .notOneOf([Yup.ref("captain"), Yup.ref('name2')], "Numele trebuie sa fie unic fata de coechipieri")
            .trim('The contact name cannot include leading and trailing spaces'),

        name2: Yup.string()
            .max(100)
            .required("Numele membrului 2 este obligatoriu")
            .notOneOf([Yup.ref("captain"), Yup.ref('name1')], "Numele trebuie sa fie unic fata de coechipieri"),
        phoneNumber: Yup.string()
            .min(10, "Numarul de telefon trebuie sa contina minim 10 cifre")
            .max(10, "Numarul de telefon trebuie sa contina maxim 10 cifre")
            .matches(
                /^(\+((\d-)?|\d{1,3}))?0\d{3}\d{3}\d{3}$/,
                "Numarul de telefon nu este valid"
            )
            .required("Numarul de telefon este obligatoriu"),

        email: Yup.string()
            .email()
            .max(100)
            .matches(/^.+@stud\.[a-z]{2,6}\.upb\.ro$/,"Email-ul nu este cel institutional")
            .required("Email-ul este obligatoriu"),
        password: Yup.string().min(8).max(100).required("Parola este obligatorie"),
        confirmationPassword: Yup.string()
            .oneOf([Yup.ref("password")], "Parolele nu coincid")
            .required("Confirmarea parolei este obligatorie"),
        cvCap: Yup.mixed()
            .required("CV-ul capitanului este obligatoriu")
            .test(
                "fileFormat",
                "CV-ul trebuie sa fie in format PDF",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test(
                "fileSize",
                "CV-ul trebuie sa aiba maxim 160KB",
                (value) => {
                    return value && value.size <= FILE_SIZE
                }
            ),
        cv1: Yup.mixed()
            .required("CV-ul membrului 1 este obligatoriu")
            .test(
                "fileFormat",
                "CV-ul trebuie sa fie in format PDF",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test(
                "fileSize",
                "CV-ul trebuie sa aiba maxim 160KB",
                (value) => value && value.size <= FILE_SIZE
            ),

        cv2: Yup.mixed()
            .required("CV-ul membrului 2 este obligatoriu")
            .test("fileFormat", "CV-ul trebuie sa fie in format PDF", (value) => {
                return value && SUPPORTED_FORMATS.includes(value.type);
            })
            .test("fileSize", "CV-ul trebuie sa aiba maxim 160KB", (value) => {
                return value && value.size <= FILE_SIZE;
            })
    });
    return (
        <section id="registerContainer">
            <Formik
                initialValues={{
                    teamName: "",
                    captain: "",
                    name1: "",
                    name2: "",
                    phoneNumber: "",
                    password: "",
                    confirmationPassword: "",
                    email: "",
                    cvCap: null,
                    cv1: null,
                    cv2: null
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(true);
                    setError(null);
                    setMessage(null);
                    let form = new FormData();
                    for (const key in values) {
                        form.append(key, values[key]);
                    }
                    sendAuthPostRequest(
                        `${process.env.REACT_APP_API_URL}/api/auth/register`,
                        form
                    )
                    .then((response) => {
                        actions.setSubmitting(false);
                        setError(null);
                        setValues(values);
                        setError(null);
                        setMessage("Inregistrare reusita! Verifica-ti mailul pentru confirmare!");
                    })
                    .catch((error) => {
                        actions.setSubmitting(false);
                        setError(error.response.data.message);
                    });
                }}                
            >
                {({ handleSubmit, setFieldValue }) => (
                    <JumboSection className="min-h-screen flex flex-col justify-center">
                        <div>
                            <Title className="text-center">
                                {isDesktop ? "Formular de înregistrare" : "Register"}
                            </Title>
                            <form onSubmit={handleSubmit} className="flex flex-wrap justify-center">
                                <div className="flex md:flex-row flex-col w-full">
                                    <div className="flex flex-col w-full md:w-1/2 px-4 md:py-4 py-0 pt-4">
                                        <TextInput
                                            label=""
                                            name="teamName"
                                            type="text"
                                            placeholder="Nume echipa"
                                            icon="group"
                                            inputGroupClassName="flex"
                                            iconClassName="text-white text-lg mt-3"
                                            popupForm
                                        />

                                        <TextInput
                                            label=""
                                            name="email"
                                            type="email"
                                            placeholder="Email capitan"
                                            icon="mail"
                                            inputGroupClassName="flex"
                                            iconClassName="text-white text-lg mt-3"
                                            popupForm
                                        />


                                        <TextInput
                                            label=""
                                            name="password"
                                            type="password"
                                            placeholder="Parola"
                                            icon="lock"
                                            inputGroupClassName="flex"
                                            iconClassName="text-white text-lg mt-3"
                                            popupForm
                                        />

                                        <TextInput
                                            label=""
                                            name="confirmationPassword"
                                            type="password"
                                            placeholder="Confirma parola"
                                            popupForm
                                            inputGroupClassName="flex"
                                            iconClassName="text-white text-lg mt-3"
                                            icon="lock"
                                        />
                                    </div>
                                    <div className="flex flex-col w-full md:w-1/2 px-4 md:py-4 py-0 pb-4">
                                        <TextInput
                                            label=""
                                            name="captain"
                                            type="text"
                                            placeholder="Nume si prenume capitan"
                                            icon="person"
                                            inputGroupClassName="flex"
                                            iconClassName="text-white text-lg mt-3"
                                            popupForm
                                        />

                                        <TextInput
                                            label=""
                                            name="name1"
                                            type="text"
                                            placeholder="Nume si prenume coechipier 1"
                                            icon="person"
                                            inputGroupClassName="flex"
                                            iconClassName="text-white text-lg mt-3"
                                            popupForm
                                        />
                                        <TextInput
                                            label=""
                                            name="name2"
                                            type="text"
                                            placeholder="Nume si prenume coechipier 2"
                                            icon="person"
                                            inputGroupClassName="flex"
                                            iconClassName="text-white text-lg mt-3"
                                            popupForm
                                        />

                                        <TextInput
                                            label=""
                                            name="phoneNumber"
                                            type="text"
                                            placeholder="Telefon capitan"
                                            popupForm
                                            icon="phone"
                                            inputGroupClassName="flex"
                                            iconClassName="text-white text-lg mt-3"
                                        />
                                    </div>
                                </div>
                                <div className="w-full flex flex-wrap">
                                    <div className="w-full md:w-1/3 p-4 flex justify-center md:justify-start">
                                        <FileInput
                                            label="CV Capitan"
                                            name="cvCap"
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/3 p-4 flex justify-center md:justify-start">
                                        <FileInput
                                            label="CV Coechipier 1"
                                            name="cv1"
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                    <div className="w-full md:w-1/3 p-4 flex justify-center md:justify-start">
                                        <FileInput
                                            label="CV Coechipier 2"
                                            name="cv2"
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                </div>
                                
                                <div className="mt-2 flex justify-center">
                                    <Button type="submit" name="white" className="mt-2 mb-2">
                                        Register
                                    </Button>
                                </div>
                            </form>
                        </div>
                        {error && <div className="text-red-500 text-center mt-4 ps-2 error">{error}</div>}
                        {message && <div className="text-green-500 text-center mt-4 ps-2 success">{message}</div>}
                    </JumboSection>
                )}
            </Formik>
        </section>
    );
}
