const Input = ({ placeholder, value, onChange, name, type = "text" }) => {
	return (
	  <input
		type={type}
		placeholder={placeholder}
		value={value}
		onChange={onChange}
		className="py-2 px-4 rounded-xl bg-white text-black border border-gray-300 focus:outline-none focus:border-gray-500"
		name={name}
	  />
	);
  };
  
  export default Input;
  