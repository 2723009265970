import { useEffect, useState } from "react";
import { sendAuthGetRequest } from "../helpers/api/Requests";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import hammerIcon from "../../assets/profile/hammer-icon.png"
import playerIcon from "../../assets/profile/player-icon.png"
import bowIcon from "../../assets/profile/bow.png"

import Button from "../helpers/buttons";
import RedirectModal from "../modals/modals";
import EditCV from "../modals/editCV";

export default function Profile({ logoutCallback }) {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const isDesktop = useMediaQuery({
		query: '(min-width: 768px)'
	})

	const [team, setTeam] = useState(undefined);
	const [error, setError] = useState("");

	useEffect(() => {
		sendAuthGetRequest(`${process.env.REACT_APP_API_URL}/api/teams/profile`, null)
			.then(res => {
				setTeam(res.data);
			})
			.catch((err) => setError(err));
	}, []);

	const navigateToMarket = () => {
		navigate('/profile/market');
	}

	const navigateToItems = () => {
		navigate('my-items');
	}

	const editProfile = () => {
		setShow(true);
	}

	return (
		<section id="profileContainer">
			{error &&
				<RedirectModal
					show={show}
					onHide={() => setError(error)}
					onCompleteAction={logoutCallback}
					type="error"
					text="A apărut o eroare. Conectează-te din nou pentru a avea acces la contul tău."
				/>}
			{
				isDesktop
					?
					<div className="min-h-screen p-0">
						<div className="min-h-[90vh] flex justify-center items-center bg-transparent">
							<div className=" w-11/12 xl:w-5/6 shadow-lg p-0 rounded-3xl flex flex-row justify-center items-center min-h-[60vh]">
								<div className='w-5/12 bg-generalBG rounded-l-3xl shadow-lg p-5 flex justify-around min-h-[60vh] h-full'>
									{team &&
										<LeftProfileComponent {...team} ></LeftProfileComponent>}
								</div>
								<div className="w-7/12 bg-center bg-no-repeat bg-cover bg-fixed bg-whiteImgBG rounded-r-3xl shadow-lg p-5 flex justify-around min-h-[60vh] h-full">
									{team && <RightProfileComponent team={team} setShow={setShow} navigateToMarket={navigateToMarket} navigateToItems={navigateToItems} editProfile={editProfile}/>}
								</div>
							</div>
						</div>
					</div>
					:
					<div className="min-h-screen p-0">
						<div className="pb-5 flex justify-center items-center my-10">
							<div className="sm:w-10/12 w-full sm:p-0 p-4 rounded-3xl flex flex-col justify-center items-center">
								<div className="sm:w-10/12 w-full bg-generalBG shadow-lg px-3 py-0 bg-cogsBG rounded-t-3xl flex flex-col">
									{team && <LeftProfileComponentMobile {...team}></LeftProfileComponentMobile>}
								</div>
								<div className="sm:w-10/12 w-full shadow-lg p-3 md:p-5 bg-circuitsBG rounded-b-3xl flex justify-center">
									{team && <RightProfileComponentMobile team={team} setShow={setShow} navigateToMarket={navigateToMarket} navigateToItems={navigateToItems} editProfile={editProfile} />
}
								</div>
							</div>
						</div>
					</div>
			}
			{team && show && <EditCV show={show} setShow={setShow} team={team} />}
		</section>
	);
}

function TeamMember(props) {
	return (
		<div className="w-full flex flex-row w-75 items-center justify-start mt-2 xl:mt-6">
			<img src={playerIcon} className="xl:w-9 w-7 h-auto mr-5 ml-8 xl:mr-5 xl:ml-14" alt="player icon" />
			<div className="my-auto px-2 text-xl text-white">{props.children}</div>
		</div>
	);
}

function LeftProfileComponent({ name, email, phoneNumber, players}) {
	return (
		<div className="flex flex-col items-center w-full">
			<div className="flex flex-row justify-center items-center mb-4 mt-5">
				<img src={hammerIcon} className="xl:w-1/4 w-1/5 h-auto self-center" alt="team icon" />
				<div className="flex flex-col justify-center items-center">
					<div className="mb-1 xl:text-5xl text-3xl text-white font-bold">{name}</div>
					<div className="mb-2 xl:text-lg text-sm text-white">{email}</div>
					<div className="mb-2 xl:text-lg text-sm text-white">{phoneNumber}</div>
				</div>
			</div>
			{players && players.map((player, index) => <TeamMember key={index}>{player}</TeamMember>)}
		</div>
	);
}

function RightProfileComponent(props) {
	return (
		<div className="flex flex-col items-center w-full">
			<div className="flex flex-row justify-center items-center mb-4 mt-5">
				<img src={bowIcon} className="w-10 m-1 self-center" alt="bow" />
				<div className="text-grey font-black text-5xl">Points: {props.team.points}</div>

			</div>
			<div className="flex flex-col justify-around items-center w-full h-full">
				<Button className="!text-2xl py-2.5 rounded-xl w-64" name="black" onClick={props.navigateToMarket}>Go to Market</Button>
				<Button className="!text-2xl py-2.5 rounded-xl w-64" name="black" onClick={props.navigateToItems}>See Your Items</Button>
				<Button className="!text-2xl py-2.5 rounded-xl w-64" name="black" onClick={() => props.setShow(true)}>Edit Profile</Button>
			</div>
		</div>
	);
}

function LeftProfileComponentMobile({ name, email, phoneNumber, players}) {
	return (
		<div className="flex flex-col min-h-[40vh] w-full">
			<div className="flex flex-col items-center">
				<div className="text-center pt-4 text-white sm:text-3xl text-2xl font-semibold">{name}</div>
				<div className="bg-white/10 sm:w-4/5 w-full border-8 border-grayBorder  mt-4">
					<div className="flex flex-col justify-center items-center">
						{players && players.map((name, index) => <div key={index} className="text-white mt-1">{name}</div>)}
					</div>
				</div>
				<div className="mt-6">
					<div className="pt-4 mt-4 mb-1 sm:text-base text-sm font-bold text-white text-center overflow-hidden">{email}</div>
					<div className="mt-1 mb-4 pb-4 sm:text-base text-sm font-bold text-white text-center">{phoneNumber}</div>
				</div>
			</div>
		</div>
	);
}

function RightProfileComponentMobile(props) {
	return (
		<div className="flex justify-center min-h-[30vh] w-full">
			<div className="flex flex-col justify-around items-center w-full">
				<div className="text-shadow text-greyTextMobile sm:text-5xl text-3xl font-bold flex flex-col items-center justify-center">
					<div className="">{props.team.points}</div>
					<div className="">Points</div>
				</div>
				<Button className="!text-xl py-2 my-3 w-4/5 rounded-xl border-0" name="gray" onClick={props.navigateToMarket}>Go to Market</Button>
				<Button className="!text-xl py-2 my-3 w-4/5 rounded-xl border-0" name="gray" onClick={props.navigateToItems}>See Your Items</Button>
				<Button className="!text-xl py-2 my-3 w-4/5 rounded-xl border-0" name="gray" onClick={() => props.setShow(true)}>Edit Profile</Button>
			</div>
		</div>
	);
}