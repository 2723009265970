import { useEffect, useState } from "react";
import io from "socket.io-client";
import Auth from "../modules/Auth";

const WSHelper = (namespace, setters, setSocket) => {
    useEffect(() => {
        let socketRef;
        socketRef = io(process.env.REACT_APP_API_URL + '/' + namespace, {
            transports: ["websocket"],
            auth: {
                token: Auth.getToken()
            }
        })
        socketRef.onAny((result, msg) => {
            if (setters.hasOwnProperty('transactions') && msg.transactions)
                setters['transactions'](msg.transactions)
            if (setters.hasOwnProperty('success') && msg.success != undefined)
                setters['success'](msg.success)
            if (setters.hasOwnProperty('message') && msg.message)
                setters['message'](msg.message)
            if (setters.hasOwnProperty('market') && msg.market)
                setters['market'](msg.market)
            if (setters.hasOwnProperty('points') && msg.points)
                setters['points'](msg.points)
            if (setters.hasOwnProperty('myMarket') && msg.myMarket)
                setters['myMarket'](msg.myMarket)
            if (setters.hasOwnProperty('teams') && msg.teams) 
                setters['teams'](msg.teams)

        })
        setSocket(socketRef);
        return () => {
            socketRef.removeAllListeners();
            socketRef.disconnect();
            socketRef.offAny();
            socketRef.offAnyOutgoing();
        }
    }, [])
}
export default WSHelper