import React from "react";
import {useState, useEffect} from "react";
import CenteredModal from "./centerModal";
import { Navigate } from "react-router-dom";
import axios from "axios";

const ConfirmationModal = (props) => {
    const [visible, setVisible] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");

    useEffect(() => {
        axios.get(`${props.url}/${props.id}`)
            .then((response) => {
                setVisible(true);
                setTitle(response.data.title);
                setBody(response.data.body);
            })
            .catch(() => setRedirect(true));
    })

    const closeModal = () => {
        if (props.callback)
            props.callback();
        setRedirect(true);
    }

    return(
        redirect ? <Navigate to={props.redirectTo} /> 
        : <CenteredModal title={title} body={body} show={visible} hide={closeModal} />
    )
}

export default ConfirmationModal;