export default function TextBox({ title, text }) {
  return (
    <div className="p-8 pb-12 sm:p-20 sm:my-20 rounded-5xl md:shadow-xl text-white mx-auto w-full md:w-2/3 xl:w-6/8 md:bg-generalBG">
      <div className="text-4xl sm:text-4xl md:text-5xl font-semibold pb-8 text-center">{title}</div>
      <div className="space-y-4 text-left ">
        {title === "Regulament" ? (
          <ol className="list-decimal sm:pl-6">
            {text.map((paragraph, index) => (
              <li key={index} className="text-sm sm:text-xl lg:text-2xl mx-4 lg:mx-2 pl-4 mb-2">
                {paragraph}
              </li>
            ))}
          </ol>
        ) : (
          text.map((paragraph, index) => (
            <p key={index} className="text-sm font-light sm:text-xl lg:text-2xl  mx-2 sm:mx-4 lg:mx-2">
              {paragraph}
            </p>
          ))
        )}
      </div>
    </div>
  );
}
