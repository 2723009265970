import { decodeToken } from 'react-jwt'
export default class Auth {
    static authenticateUser(token) {
        localStorage.setItem('token', token);
    }
    static isUserAuthenticated() {
        return localStorage.getItem('token') !== null;
    }
    static isAdmin() {
        return decodeToken(localStorage.getItem('token')).isAdmin;
     }
    static deauthenticateUser() {
        localStorage.removeItem('token');
    }
    static getToken() {
        return localStorage.getItem('token');
    }
}