import React, { useState, useEffect } from "react";
import SectionHeader from "../../components/section/sectionHeader";
import { sendAuthGetRequest } from "../../components/helpers/api/Requests";
import CustomModal from "../../components/modals/modals";
import Button from "../../components/helpers/buttons";
import io from "socket.io-client";
import WSHelper from '../../Websockets/Websockets';

export default function MarketPage({ card: MarketCard, url: url }) {
  const [items, setItems] = useState([]);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [loading, setLoading] = useState(true);
  const [socket, setSocket] = useState(io);
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [showMarketInfoModal, setShowMarketInfoModal] = useState(false);

  const messageSetter = (message) => {
    setMessage(message);
    setShow(true);
  };

  const successSetter = (suc) => {
    setSuccess(suc);
  };

  const itemSetter = (items) => {
    setItems(items);
  };

  const pointsSetter = (points) => {
    setCurrentPoints(points);
  };

  WSHelper('users', { "message": messageSetter, "success": successSetter, "market": itemSetter, "points": pointsSetter }, setSocket);

  useEffect(() => {
    sendAuthGetRequest(`${process.env.REACT_APP_API_URL}/api/items/${url}`, null)
      .then(res => {
        setItems(res.data.items);
        setCurrentPoints(res.data.points);
        setLoading(false);
      })
      .catch((err) => { });
  }, []);

  const closeModal = () => {
    setShow(false);
    setTimeout(() => {
      setMessage({});
    }, 500);
  };

  const openMarketInfoModal = () => {
    setShowMarketInfoModal(true);
  };

  const closeMarketInfoModal = () => {
    setShowMarketInfoModal(false);
  };

  return (
    <>
      <div className="py-10 min-h-screen">
        <SectionHeader>
          <p className="text-5xl md:text-5xl">Market</p>
          <br />
          <p className="text-xl md:text-2xl">Points: {currentPoints}</p>
        </SectionHeader>
        <div className="flex justify-center mt-8">
          <Button className="mb-6 !px-8 !py-4" name="gray" onClick={openMarketInfoModal}>
            Market Info
          </Button>
        </div>
        {loading && <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-gray-900" />}
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {items.map((item, index) => (
            <div key={index} className="flex justify-center mt-8">
              <MarketCard
                _id={item._id}
                socket={socket}
                key={index}
                priceChange={item.priceChange}
                buyPrice={item.buy}
                capacity={item.capacity}
                objectName={item.name}
                image={item.filePath}
                item={item}
              />
            </div>
          ))}
        </div>
      </div>
      <CustomModal show={show} onHide={closeModal} type={success ? "success" : "error"} text={message} />
      {showMarketInfoModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-generalBG rounded-lg p-12 max-w-xl w-full">
            <div className="text-center mb-8">
              <p className="text-white text-xl md:text-2xl font-semibold">Market Info</p>
            </div>
            <div className="mb-8 text-center">
              <p className="text-white text-lg md:text-xl font-medium">Prețurile din Shop nu sunt fixe. Acestea variază în timp, în funcție de cât de mare este cererea pentru fiecare produs în parte. După o perioadă în care a fost cumpărat un număr semnificativ de obiecte de același tip, prețul acelui obiect va crește. În cazul în care cererea pentru un anumit produs nu este la fel de mare, prețul acestuia va suferi o scădere. Aceste schimbări vor fi semnalate cu ajutorul graficii din Shop prin săgeți verzi sau roșii adăugate lângă prețul obiectelor.</p>
            </div>
            <div className="text-center">
              <Button name="black" className="modal-button" onClick={closeMarketInfoModal}>
                <h3 className="font-semibold text-white text-lg md:text-xl">Închide</h3>
              </Button>
            </div>
          </div>
        </div>
      )}

    </>
  );
}