import React from 'react';
import { useMediaQuery } from 'react-responsive';
import SponsorModal from './sponsorModal';

export default function OfficialSponsor({ link, description, img }) {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  let [showModal, setShowModal] = React.useState(false);

  const handleClose = () => {
    setShowModal(false);
    document.body.style.overflow = 'auto';
  };

  const handleShow = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <>
      {isDesktop ? (
        <div className="flex items-center justify-center my-5 pb-5">
          <div className="flex w-4/6 md:w-11/12 lg:w-3/4 rounded-5xl shadow-lg">
            <div className="bg-generalBG rounded-l-5xl text-center px-8 py-12 w-1/2">
              <p className="md:text-sm lg:text-lg text-white mb-4 font-light">{description}</p>
              <a href={link} className="text-white underline hover:!text-gray-400 md:text-sm lg:text-lg">
                {link}
              </a>
            </div>
            <div className="bg-whiteImgBG bg-center bg-cover bg-fixed rounded-r-5xl p-5 w-1/2 flex items-center justify-center bg-no-repeat">
              <img src={img} alt="Official Sponsor" className='w-80' />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="border border-gray-300 shadow-md rounded-xl bg-generalBG flex flex-col justify-center items-center p-6 mx-8 my-6">
            <div className="flex items-center justify-center w-78 mb-4">
              <img
                alt="official-sponsor-mobile"
                src={img}
                className="w-full filter drop-shadow-md"
              />
            </div>
            <p className="text-xl font-bold text-gray-300 mb-2">Sponsor Oficial</p>
            <p className="text-md text-gray-300 underline font-semibold cursor-pointer" onClick={handleShow}>
              Afla mai multe
            </p>
            {showModal && (
              <SponsorModal
                img={img}
                description={description}
                link={link}
                onClose={handleClose}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
