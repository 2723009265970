import Table from '../../components/admin/table';
import TitleBox from '../../components/helpers/titleBox';
import Button from '../../components/helpers/buttons';
import { useNavigate } from 'react-router-dom';
import { sendAuthGetRequest, sendAuthPostRequest } from '../../components/helpers/api/Requests';
import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Auth from '../../modules/Auth';
import WSHelper from '../../Websockets/Websockets';

const AdminPage = () => {
  const navigate = useNavigate();
  const id = 3;
  const headers3 = ['Echipa', 'Puncte', '', '', ''];
  const [error, setError] = useState("");
  const [teams, setTeams] = useState([]);
  const [socket, setSocket] = useState(io);
  const [refresh, setRefresh] = useState(false);
  const [intervalStarted, setIntervalStarted] = useState(false);

  useEffect(() => {
    sendAuthGetRequest(`${process.env.REACT_APP_API_URL}/api/admin/get-teams`, null)
      .then(res => {
        teamSetter(res.data);
      })
      .catch((err) => {
        setError(err)
      });
  }, [refresh]);

  const teamSetter = (teams) => {
    const mappedTeams = teams.map(team => Object.values(team));
    setTeams(mappedTeams);
  };

  WSHelper('admins', { "teams": teamSetter }, setSocket);

  useEffect(() => {
    sendAuthPostRequest(`${process.env.REACT_APP_API_URL}/api/admin/check-interval`)
        .then(res => {
            setIntervalStarted(res.data.isIntervalRunning);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
  }, []);

  const navigateToMarket = () => {
    navigate("/admin/market");
  }

  const navigateToTransactions = () => {
    navigate("/admin/transaction");
  }

  const handleStartStopInterval = () => {
    const url = intervalStarted ?
      `${process.env.REACT_APP_API_URL}/api/admin/stop-interval` :
      `${process.env.REACT_APP_API_URL}/api/admin/start-interval`;

    sendAuthPostRequest(url)
      .then(res => {
        socket.emit('interval', { isIntervalRunning: intervalStarted });
        setRefresh(prevState => !prevState);
        setIntervalStarted(prevState => !prevState);
        console.log(res.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const navigateToMesteri = () => {
    navigate("/admin/mesteri");
  }

  return (
    <div className="w-full min-h-screen flex flex-col justify-start items-center py-6 md:mt-12 mt-6">
      <TitleBox title="ECHIPE" />
      <div className='mx-4 sm:mx-0 w-5/6 bg-generalBG md:p-12 p-4 rounded-5xl shadow-2xl flex flex-col md:my-8 my-4'>
        <div className='flex flex-col items-center w-full lg:justify-end justify-center mb-6 md:flex-row'>
          <Button className="text-center font-bold px-3 py-1 rounded-xl mt-2 mx-2 w-1/2 lg:w-1/5 text-xs md:text-xl" name="gray" onClick={handleStartStopInterval}>
            {intervalStarted ? "Stop Interval" : "Start Interval"}
          </Button>
          <Button className="text-center font-bold px-3 py-1 rounded-xl mt-2 mx-2 w-1/2 lg:w-1/5 text-xs md:text-xl" name="gray" onClick={navigateToMarket}>
            Items Market
          </Button>
          <Button className="text-center font-bold px-3 py-1 rounded-xl mt-2 mx-2 w-1/2 lg:w-1/5 text-xs md:text-xl" name="gray" onClick={navigateToTransactions}>
            Transactions
          </Button>
          <Button className="text-center font-bold px-3 py-1 rounded-xl mt-2 mx-2 w-1/2 lg:w-1/5 text-xs md:text-xl" name="gray" onClick={navigateToMesteri}>
            Meșteri
          </Button>
        </div>
        <Table id={id} headers={headers3} rows={teams} socket={socket} setSocket={setSocket} />
      </div>
    </div>
  );
};

export default AdminPage;
