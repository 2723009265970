import Table from '../../components/admin/table';
import TitleBox from '../../components/helpers/titleBox';
import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Auth from '../../modules/Auth';
import WSHelper from '../../Websockets/Websockets';

const AdminTransaction = () => {
    const id = 1;
    const headers = ['Team Name', 'Product Name', 'Action', 'Price', 'Deliver'];
    const [transactions, setTransactions] = useState({ "list": [] });
    const [error, setError] = useState("");
    const [socket, setSocket] = useState(io);
    const [refresh, setRefresh] = useState(false);
    const transactionSetter = (transactions) => {
        const mappedTrans = transactions.map(trans => [
            trans._id,
            trans.team,
            trans.item,
            trans.actionType,
            trans.price.toString(),
            trans.isDelivered
        ]);
        setTransactions(mappedTrans);
    };
    
    WSHelper("admins", { "transactions": transactionSetter }, setSocket);
    if (!Array.isArray(transactions)) {
        return <></>;
    }

    return (
        <div className="w-full min-h-screen flex flex-col justify-start items-center py-6 md:mt-12 mt-6">
            <TitleBox title="TRANSACTIONS" />
            <div className='mx-4 sm:mx-0 w-5/6 bg-generalBG md:p-12 p-4 rounded-5xl shadow-2xl flex flex-col md:my-8 my-4'>
                <Table id={id} headers={headers} rows={transactions} socket={socket} setSocket={setSocket}/>
            </div>
        </div>
    );
};

export default AdminTransaction;
