import React from "react";

export default function Button(props) {
    const getButtonClasses = () => {
        switch (props.name) {
            case "black":
                return "bg-blackBTN text-white font-bold rounded-full border-2 border-blackBTN px-4 py-1.5 text-lg shadow-md hover:bg-blackBTN-hover hover:border-blackBTN-hover active:bg-blackBTN-hover active:border-blackBTN-hover";
            case "white":
                return "bg-whiteBTN text-gray-800 font-bold rounded-full border-2 border-whiteBTN px-4 py-1.5 text-lg shadow-md hover:bg-whiteBTN-hover hover:border-whiteBTN-hover active:bg-whiteBTN-hover active:border-whiteBTN-hover";
            case "gray":
                return "bg-grayBTN text-white font-bold rounded-full border-2 border-gray-700 px-4 py-1.5 text-lg shadow-md hover:bg-grayBTN-hover hover:bg-grayBTN-hover active:bg-grayBTN-hover active:border-gray-700";
            case "admin":
                return "bg-grayBTN text-white font-medium rounded-2xl px-8 py-1 text-xl sm:text-2xl lg:text-3xl shadow-md hover:bg-grayBTN-hover hover:bg-grayBTN-hover active:bg-grayBTN-hover active:border-gray-700";
            default:
                // Default case, return some default button styles or an empty string
                return "";
        }
    };

    return (
        <button
            className={`${getButtonClasses()} ${props.className}`}
            id={props.id}
            onClick={props.onClick}
            type={props.type || "button"}
        >
            {props.children}
        </button>
    );
}
